@import "src/styles/animations";
@import "src/styles/fonts";

body {
  position: relative;
  margin: 0;
  font-family: 'Lato Regular', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  outline: none !important;
}

button {
  cursor: pointer;
  font-family: inherit !important;
  outline: none !important;

  &:active {
    outline: none;
  }
}
